.cta {
  background-image: url('../img/bg-btn.png');
  background-repeat: no-repeat;
  background-size: contain;

  display: inline-block;
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  font-weight: 500;
  text-align: center;
  border-radius: 38px;
  pointer-events: auto;

  height: 45px;
  width: 140px;
  line-height: 3.5;
  font-size: 13px;

  font-family: 'Comfortaa', cursive;
  box-shadow: 0 3px 6px 1px rgba(91, 91, 91, 0.2);
  &:hover {
    color: white;
  }
  @media screen and (min-width: 575px){
    width: 152px;
    height: 49px;
    line-height: 3.7;
  }
  @media screen and (min-width: 998px){
    width: 196px;
    font-size: 17px;
    height: 63px;
    line-height: 3.8;
    &--offer {
      width: 206px;
      height: 66px;
      font-size: 18px;
    }
  }
  @media screen and (min-width: 1600px){
    font-size: 20px;
    width: 244px;
    height: 78px;
    line-height: 4;
  }
}

.link {
  color: black;
  opacity: 0.89;
  font-weight: 500;
  font-family: 'Comfortaa', cursive;
  pointer-events: auto;
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #000;
    display: block;
    position: absolute;
    bottom: 30%;
    @media screen and (max-width: 767px){
      bottom: 0;
    }
  }
  &:hover {
    color: black;
  }
  @media screen and (min-width: 767px){
    line-height: 3;

    font-size: 20px;
  }
  @media screen and (min-width: 1600px){
    font-size: 24px;
  }
}

.transparent-btn {
  background-color: transparent;
  border: none;
}