.glass {
  background-repeat: no-repeat;
  background-size: contain;
  &-wrap {
    @media screen and (min-height: 735px) and (min-width: 1200px) {
      position: absolute;
      top: 58%;
      transform: translateY(-50%);
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      top: 49%;
    }
    @media screen and (max-height: 734px) {
      position: static;
      transform: scale(1);
    }
    @media screen and (max-width: 1200px) {
      position: static;
      transform: scale(1);
    }
    .title-bread {
      margin-top: 20px;
      margin-right: 30px;
      @media screen and (min-width: 576px) {
        margin-top: 50px;
      }
      @media screen and (min-height: 735px) and (min-width: 668px) {
        margin-top: 0;
        margin-right: 0;
        position: relative;
        top: -70px;
        right: 50px;
      }
      @media screen and (min-width: 1200px) {
        top: -95px;
      }
    }
    &--untouch {
      pointer-events: none;
    }
    &--main{
      position: absolute;
      top: 54%;
      transform: translateY(-50%);
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &-inner {
    justify-content: center;
    display: flex;
  }
  &--standart {
    background-image: url('../img/glass.png');
    width: 492px;
    height: 328px;
    @media screen and (max-width: 767px) {
      width: 310px;
      height: 220px;
    }
    @media screen and (min-width: 1200px) {
      width: 711px;
      height: 458px;
    }
  }
  &__visible {
    position: absolute;
    top: 5px;
    right: 12px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: opacity .2s ease-in;
    opacity: 0;
    pointer-events: none;
    background-image: url('/assets/img/eye2.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (min-width: 900px) {

    }
    @media screen and (min-width: 1200px) {
      top: 15px;
      right: 15px;
      width: 40px;
      height: 40px;
    }
  }
}
.slick-center .glass__visible  {
  opacity: 1;
  pointer-events: auto;
}