header {
  padding-top: 15px;
  @media screen and (min-width: 998px){

  }
  @media screen and (min-width: 1200px){
    padding-top: 30px;
    position: relative;
  }
  .logo {
    height: 58px;
    position: relative;
    z-index: 11;
    @media screen and (min-width: 998px){
      height: 69px;
    }
  }
}