@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Comfortaa:wght@300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

html {
  height: -webkit-fill-available;
}

body {
  background-image: url('../img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  min-height: -webkit-fill-available;
  background-position: center;
  background-attachment: fixed;
  @media screen and (max-height: 735px){
    overflow: auto;
  }
  @media screen and (max-width: 1200px){
    overflow: auto;
  }
}
.underline {
  text-decoration: underline;
}
.live-color {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
a {
  text-decoration: none;
  color: #212529;
  &:hover {
    color: #212529;
  }
}
.main__subtitle {
  font-family: 'Comfortaa', cursive;
  font-weight: 600;
  opacity: .86;
}
.main__title {
  font-size: 133px;
  line-height: 0.5;
  .white {
    font-size: 49px;
    @media screen and (min-width: 1200px) {
      font-size: 72px;
    }
    @media screen and (max-width: 767px){
      font-size: 30px;
    }
  }
  @media screen and (min-width: 1200px) {
    font-size: 185px;
  }
  @media screen and (max-width: 767px){
    font-size: 77px;
  }
}