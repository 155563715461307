.clue {
  text-align: center;
  opacity: .5;
  transition: opacity .3s ease-in;

  &--mobile {
    color: white;
    img {
      width: 14px;
      &:first-child {
        transform: rotate(180deg);
      }
    }
  }
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  img {
    margin-top: -5px;
  }
  &--vertical {
    width: 175px;
    transform-origin: bottom;
    pointer-events: none;
    transform: rotate(90deg) translateY(-50%);
    position: fixed;
    right: 0;
    top: 50%;
    color: #9AA0A6;
    transition: opacity .2s ease-in-out;
    @media screen and (max-width: 1200px) {
      display: none;
    }
    img {
      width: 30px;
      transform: rotate(270deg);
    }
  }
}