.portfolio-modal {

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  text-align: center;
  overflow: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  transition: opacity .3s ease-in;
  pointer-events: none;
  opacity: 0;
  &__page {
    max-width: 1400px;
    width: calc(100% - 15px);
    background-color: #fff;
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
    @media screen and (min-width: 490px) {
      width: calc(100% - 100px);
    }
  }
  &__shadow {
    background-color: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
}