.offer {
  .about__title {
    margin-bottom: 15px;
  }
  &__list {
    list-style-type: none;
    position: relative;
    padding-top: 10px;
    margin-top: 10px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      background-color: #8b958c;
      height: 1px;
      width: 100%;
      @media screen and (min-width: 991px){
        width: 200%;

      }
      @media screen and (min-width: 1200px){
        height: 100%;
        width: 1px;
      }

    }
    li {
      font-family: 'Comfortaa', cursive;
      font-weight: 300;
      font-size: 16px;
      @media screen and (min-width: 1200px){
        font-size: 18px;
      }
    }
  }
  &__price {
    text-align: center;
    @media screen and (min-width: 991px) and (max-width: 1199px){
      margin-top: 20px;
    }
    @media screen and (min-width: 1200px){
      padding-top: 10px;
      margin-top: 10px;
    }
    span{
      display: block;
      &:first-child {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
      &:nth-child(2) {
        font-family: 'Comfortaa', cursive;
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 1rem;
      }
    }
  }
  &__price, &__list {

    @media screen and (min-width: 1200px){
      padding-top: 0;
      margin-top: 0;
    }
  }
  &__desc {
    &:before {
      content: '';
      display: inline-block;
      padding-left: 38px;
    }
  }
  @media screen and (max-width: 1500px) and (min-width: 1200px){
    &__desc {
      &--hide {
        display: none;
      }
    }
    .glass {
      position: relative;
    }
    &__price {
      position: absolute;
      bottom: 48px;
      left: 50px;
      display: flex;
      align-items: center;
      span:nth-child(2) {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
  }
}