.step {
  &__col {
    @media screen and (min-width: 992px) {
      flex-basis: 50%;
      width: 50%;
    }
  }
  //.about__title {
  //  margin-bottom: 19px;
  //}
  &__white {
    position: relative;
    right: -40px;
    @media screen and (min-width: 1200px) {
      right: -80px;
    }
    @media screen and (min-width: 1500px) {
      right: -150px;
    }
  }
  &__black {
    position: relative;
    left: -40px;
    @media screen and (min-width: 1200px) {
      left: -80px;
    }
    @media screen and (min-width: 1500px) {
      left: -150px;
    }
  }
  &__title {
    font-family: 'Comfortaa', cursive;

    font-weight: 700;
    font-size: 20px;
    @media screen and (min-width: 576px){
      font-size: 25px;
    }
    @media screen and (min-width: 1500px) {
      font-size: 36px;
    }

    @media screen and (max-width: 991px){
      &--divider {
        position: relative;
        &::after {
          width: 100%;
          height: 1px;
          top: -10px;
          position: absolute;
          background-color: #8b958c;
          content: '';
          display: block;
        }
      }
    }
  }

  &__number {
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 0.7;
    margin-right: 10px;
    padding-left: 20px;
  }

  &__desc {
    line-height: 1.3;
  }

  &__dividers {
    @media screen and (min-width: 992px){
      position: relative;
      &::before {
        width: 1px;
        height: 200%;
        right: 0;
      }
      &::after {
        width: 200%;
        height: 1px;
        bottom: 0;
      }
      &::before, &::after {
        position: absolute;
        background-color: #8b958c;
        content: '';
        display: block;
      }
    }
  }
}