.contacts {
  @media screen and (min-width: 1200px){
    &__divider {
      position: relative;
      &::before {
        content: '';
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #8b958c;
        width: 1px;
        display: block;
      }
    }
  }
  &__address {
    div {
      margin-right: 15px;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }
  &__socials {
    position: relative;
    @media screen and (min-width: 1500px) {
      margin-bottom: 3rem;
    }
    img {
      width: 42px;
      height: 42px;
      @media screen and (min-width: 1440px){
        width: 56px;
        height: 56px;
      }
      display: block;
      transform: scale(1);
      transition: transform .2s ease-in-out;
      &:hover {
        transform: scale(1.15);
      }
    }
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 134px;
      background-color: #8b958c;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 1200px) {
      &--only-desk {
        &::before {
          content: none;
        }
      }
    }
  }
}