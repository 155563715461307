.technology {
    &__category {
      &::after {
        content: '';
        flex: auto;
      }
    }
  img {
    max-height: 55px;
    width: auto;
  }
  figure {
    text-align: center;
    width: 76px;
    @media screen and (min-width: 410px){
      width: 82px;
    }
    @media screen and (min-width: 992px){
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  &__divider {
    position: relative;
    &--1 {
      &::before {
        @media screen and (min-width: 1200px) {
          width: 65%;
        }
        @media screen and (min-width: 1400px) {
          width: 70%;
        }
      }
    }
    &--2 {
      &::before {
        @media screen and (min-width: 1200px) {
          width: 67%;
        }
        @media screen and (min-width: 1400px) {
          width: 72%;
        }
      }
    }
    &--3 {
      &::before {
        @media screen and (min-width: 1200px) {
          width: 60%;
        }
        @media screen and (min-width: 1400px) {
          width: 65%;
        }
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;

      top: 50%;
      transform: translateY(-50%);
      background-color: #8b958c;
      height: 1px;
    }
  }
}