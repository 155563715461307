.about {
  min-width: 100%;
  min-height: calc(100% - 120px);
  overflow: hidden;
  position: relative;
  &__shadow {
    display: none;
    @media screen and (min-height: 735px) and (min-width: 1200px) {
      opacity: 0;
      pointer-events: none;
      display: block;
    }
  }
  &__window {
    @media screen and (max-height: 735px){
      margin-top: 50px;
      margin-bottom: 50px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media screen and (max-width: 1200px){
      margin-top: 50px;
      margin-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;
    }

    right: 0;
    transition: right .5s ease-in-out, top .5s ease-in-out;
   &--1 {
      top: 50%;
    }
    &:not(&--1) {
      top: 200%;
    }
  }
  //.glass {
  //  background-image: url('../img/a.png');
  //  width: 1391px;
  //  height: 574px;
  //  padding: 53px;
  //  &--big {
  //    background-image: url('../img/about-glass.png');
  //  }
  //}
  //&__title {
  //  margin-top: -89px;
  //  font-size: 160px;
  //  line-height: 0.4;
  //  .white {
  //    font-size: 60px;
  //  }
  //}
  .glass {
    @media screen and (max-width: 1199px) {
      padding: 30px;
      max-width: 898px;
      background-image: url('../img/mobile-glass.png');
      background-size: cover;
      background-repeat: repeat;
      position: relative;
      border: 5px solid;
      border-image-slice: 1;
      border-width: 2px;
      background-color: rgba(255,255,255,0.1);
      border-image-source: linear-gradient(to left, rgba(#e4f4eb, .2), rgba(#fcecf5, .2));
    }
    @media screen and (max-width: 991px){
      max-width: 537px;
    }
    @media screen and (max-width: 991px){
      padding: 15px;
    }
    @media screen and (min-width: 1200px) {
      background-image: url('../img/a.png');
      height: 371px;
      width: 900px;
      padding: 53px 50px;
    }
    @media screen and (min-width: 1500px) {
      height: 623px;
      width: 1391px;
      padding: 53px 83px;
    }
    @media screen and (min-width: 1600px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    &--big {
      //background-color: #000;

      @media screen and (min-width: 1200px) {
        background-image: url('../img/about-glass.png');
      }
      @media screen and (min-width: 1200px) and (max-width: 1500px) {
        height: 494px;
        width: 1103px;
        //padding: 53px 88px;
      }
    }
  }
  &__title {
    line-height: .7;
    font-size: 40px;
    margin-top: -40px;
    margin-bottom: 35px;
    .white {
      font-size: 19px;
      @media screen and (min-width: 490px) {
        font-size: 30px;
        margin-top: -54px;

      }
      @media screen and (min-width: 668px) {
        font-size: 40px;

      }
      @media screen and (min-width: 1500px) {
        font-size: 60px;
      }
    }
    @media screen and (min-width: 490px) {
      font-size: 60px;

    }
    @media screen and (min-width: 668px) {
      font-size: 70px;
      margin-top: -75px;
      //margin-top: -75px;

      //margin-bottom: 35px;
    }
    @media screen and (min-width: 1200px) {
      margin-top: -97px;
    }
    @media screen and (min-width: 1500px) {
      margin-top: -89px;
      margin-bottom: 3rem;
      line-height: 0.4;
      font-size: 160px;
    }
  }
}