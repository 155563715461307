.title-bread {
  font-size: 18px;
  text-align: right;
  font-family: 'Comfortaa', cursive;
  @media screen and (min-width: 668px) {
    font-size: 30px;
    margin-bottom: 0;
  }
}
.title-mega {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 700;
  margin-top: 0;
  .black {
    display: block;
    text-shadow: 0 6px 6px rgb(1, 1, 4);
    color: black;
    opacity: .70;
  }
  .white {
    color: white;
    opacity: 0.57;
  }
}
.headline {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1;
}
p {
  font-family: 'Comfortaa', cursive;
  font-weight: 500;
  margin-bottom: 0;

  font-size: 16px;

  //@media screen and (min-width: 576px){
  //  font-size: 18px;
  //}
  @media screen and (min-width: 1200px){
    font-size: 18px;
  }
  @media screen and (min-width: 1500px){
    //font-size: 27px;
    font-size: 24px;
  }
}